import client1 from "../assets/client-logos/client-1.png";
import client2 from "../assets/client-logos/client-2.png";
import client3 from "../assets/client-logos/client-3.png";
import client4 from "../assets/client-logos/client-4.png";
import client5 from "../assets/client-logos/client-5.png";
import client6 from "../assets/client-logos/client-6.png";
import client7 from "../assets/client-logos/client-7.png";
import client8 from "../assets/client-logos/client-8.png";
import client9 from "../assets/client-logos/client-9.png";
import client10 from "../assets/client-logos/client-10.png";
import client11 from "../assets/client-logos/client-11.png";
import client12 from "../assets/client-logos/client-12.png";
import client13 from "../assets/client-logos/client-13.png";
import client14 from "../assets/client-logos/client-14.png";
import client15 from "../assets/client-logos/client-15.png";
import client16 from "../assets/client-logos/client-16.png";
import client17 from "../assets/client-logos/client-17.png";
import client18 from "../assets/client-logos/client-18.png";
import client19 from "../assets/client-logos/client-19.png";
import client20 from "../assets/client-logos/client-20.png";
import client21 from "../assets/client-logos/client-21.png";
import client22 from "../assets/client-logos/client-22.png";
import client23 from "../assets/client-logos/client-23.png";
import client24 from "../assets/client-logos/client-24.png";
import client25 from "../assets/client-logos/client-25.png";
import client26 from "../assets/client-logos/client-26.png";
import client27 from "../assets/client-logos/client-27.png";

const data = [
  { logo: client5 },
  { logo: client6 },
  { logo: client2 },
  { logo: client3 },
  { logo: client4 },
  { logo: client7 },
  { logo: client8 },
  { logo: client1 },
  { logo: client9 },
  { logo: client10 },
  { logo: client11 },
  { logo: client12 },
  { logo: client13 },
  { logo: client14 },
  { logo: client15 },
  { logo: client16 },
  { logo: client17 },
  { logo: client18 },
  { logo: client19 },
  { logo: client20 },
  { logo: client21 },
  { logo: client22 },
  { logo: client23 },
  { logo: client24 },
  { logo: client25 },
  { logo: client26 },
  { logo: client27 },
];

export default data;
